import React from 'react';
import Link from 'next/link';
import styled from '@emotion/styled';
import { ThemeProps } from '@theme/base';
import { PlainLinkStyles } from '@styles/common';
import { Icons, formatIconName } from '@model/common';
import { CarouselTile as CarouselTileModel } from '@model/content';
import { TestId } from '@components/test-ids';
import { Icon } from '@components/common/generic-icon';
import { RippleCard } from '@components/common/ripple-card';
import { Button, ButtonSize, ButtonColor } from '@components//material-ui/button/Button';
import { ConditionalWrapper } from '@util/common';

/* ***************** *
 *       Types       *
 * ***************** */
export interface CarouselTileProps extends CarouselTileModel {
  testId?: string;
}

/* ***************** *
 *       Styles      *
 * ***************** */
const Container = styled.div(({ theme }: ThemeProps) => ({
  background: theme.custom.colors.white,
  borderRadius: 10,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  height: '100%',
  alignItems: 'center',
  padding: theme.custom.spacing.medium,
  border: `1px solid ${theme.custom.colors.group10.light}`,
  boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px',
  color: theme.custom.colors.black,

  ['i']: {
    fontSize: '4rem',
    color: theme.custom.colors.group1.base,
    transition: `color ${theme.custom.transitions.default}`
  }
}));

const Title: any = styled.h5(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.h5 as any),
  margin: '10px 0',
  textAlign: 'center'
}));

const Description: any = styled.p(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  margin: 0,
  marginBottom: theme.custom.spacing.medium,
  textAlign: 'center'
}));

const StyledButton = styled(Button)(() => ({
  alignSelf: 'stretch',
  borderRadius: 10,
  height: 50,
  marginTop: 'auto',
  overflow: 'hidden'
}));

/* ************************ *
 *    OfferTileComponent    *
 * ************************ */

export const CarouselTile = ({ testId, link, heading, message, icon, ctaLabel }: CarouselTileProps) => {
  return (
    <ConditionalWrapper
      condition={!!link}
      wrapper={(children) => (
        <RippleCard style={{ borderRadius: '0px' }}>
          <Link prefetch={false} href={link} passHref style={PlainLinkStyles}>
            {children}
          </Link>
        </RippleCard>
      )}
    >
      <Container className={'slide-container'} data-testid={testId || TestId.dynamicCarousel.tile}>
        {icon && <Icon name={Icons[formatIconName(icon)]} />}
        <Title>{heading}</Title>
        <Description>{message}</Description>
        {ctaLabel && <StyledButton label={ctaLabel} color={ButtonColor.PRIMARY} size={ButtonSize.FLUID} />}
      </Container>
    </ConditionalWrapper>
  );
};
