import { Name } from '@model/iceberg';
import { TripTypes } from '@model/common/tours';

export enum GeographyType {
  CONTINENT = 'continent',
  COUNTRY = 'country',
  REGION = 'region',
  RESORT = 'resort',
  HOTEL = 'hotel'
}

export interface GeographyTripType {
  tripType: TripTypes;
  count: number;
}

export interface GeographyItem extends Name {
  type: GeographyType;
  isDestination: boolean;
  isSearchable: boolean;
}

export interface Continent extends GeographyItem {
  type: GeographyType.CONTINENT;
  countries: Array<Country>;
}

export interface Country extends GeographyItem {
  type: GeographyType.COUNTRY;
  isoCode: string;
  regions: Array<Region>;
  tours: Array<Tour>;
  group?: string | null;
  tripTypes?: Array<GeographyTripType>;
}

export interface Region extends GeographyItem {
  type: GeographyType.REGION;
  resorts: Array<Resort>;
  group?: string | null;
  tripTypes?: Array<GeographyTripType>;
}

export interface Resort extends GeographyItem {
  type: GeographyType.RESORT;
  holidays: Array<Hotel>;
  group?: string | null;
  tripTypes?: Array<GeographyTripType>;
}

export interface Hotel extends Name {
  type: GeographyType.HOTEL;
}

export interface Tour extends Name {
  type: TripTypes;
}

export const isRegion = (destination: Country | Region): destination is Region =>
  (destination as Region).resorts !== undefined;
