import { GetStaticProps } from 'next';
import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';
import dynamic from 'next/dynamic';
import { withTheme } from '@emotion/react';
import { Theme } from '@theme/base';
import { SectionSeparator } from '@styles/layout';
import { withGlobalProvider } from 'providers/globalProvider';
import { PageProps } from '@model/pages';
import { CmsPages, TripTypes, CMSDynamicComponentTypes } from '@model/contentful';
import { Revalidate } from '@model/config/revalidate';
import { AppVariant } from '@model/config/brands';
import { ERROR_CODES } from '@model/common/error-codes';
import { CarouselTile } from '@model/content';
import { ContentApi } from '@model/iceberg/service/content';
import { ContentfulApi } from '@model/contentful/service';
import { GenericPageConfig } from '@model/contentful/common/generic-page-config';
import { storeWrapper } from '@state/store';
import { initializeApp } from '@state/initializeApp';
import { NavBarContainer } from '@components/navigation';
import { Footer } from '@components/footer';
import { Error500 } from '@components/common/hero/error/Error500';
import { RecentlyViewedCarouselContainer } from '@components/recently-viewed/RecentlyViewedCarouselContainer';
import { getHomeMeta } from '@components/meta/get-home-meta';
import { useI18NextContext } from '@components/hooks';
import { LazyLoadComponent } from '@components/hoc';
import { SectionTitleSizes, Head } from '@components/common';
import { CMSComponents } from '@components/cms';
import { Usp } from '@components/usp';
import { getHeroData } from '@components/util';
import { getHTML } from '@util/common';

export interface HomePageProps extends PageProps {
  theme: Theme;
  cmsConfig: GenericPageConfig;
  usp: Array<CarouselTile>;
  carousel: Array<CarouselTile>;
  subCarousel: Array<CarouselTile>;
}

const HomeCarousel = dynamic(() => import('../src/components/homepage/HomeCarousel').then((e) => e.HomeCarousel), {
  ssr: false
});
const SearchTabsBar = dynamic(() => import('../src/components/search/SearchTabsBar'));

const HomePage = ({ cmsConfig, usp, carousel, subCarousel, promoBannerTripType }: HomePageProps) => {
  const t = useI18NextContext();
  const router = useRouter();

  useEffect(() => {
    router.prefetch('/search');
  }, []);
  const isWhiteLabel = AppVariant.isWhitelabel();

  if (!cmsConfig) return <Error500 code={ERROR_CODES.NO_CMS_CONFIG} />;

  const components = cmsConfig.components;
  const tripTypesIndex = components.findIndex(
    ({ fields }) => fields.componentId === CMSDynamicComponentTypes.homepageTripTypes
  );
  const onSearch = () => {};
  const nonTripTypesIndexes = components.map((_, idx) => idx);
  nonTripTypesIndexes.splice(tripTypesIndex, 1);

  return (
    <>
      <Head metaTitle={t('meta__title--homepage')} metaDescription={cmsConfig.metaDescription} />
      {!isWhiteLabel && (
        <Script type="application/ld+json" dangerouslySetInnerHTML={getHTML(JSON.stringify(getHomeMeta()))} />
      )}
      <NavBarContainer
        promoBannerTripType={promoBannerTripType}
        renderSearch={<SearchTabsBar condensed onSearch={onSearch} />}
      />
      <HomeCarousel tiles={carousel} subTiles={subCarousel} />
      <SectionSeparator />
      <LazyLoadComponent>
        {!isWhiteLabel && (
          <CMSComponents
            components={components}
            displayIndexes={[tripTypesIndex]}
            titleSize={SectionTitleSizes.LARGE}
          />
        )}
        <Usp tiles={usp} />
        {isWhiteLabel && <SectionSeparator />}
        <CMSComponents
          components={components}
          displayIndexes={nonTripTypesIndexes}
          titleSize={SectionTitleSizes.LARGE}
        />
        {!isWhiteLabel && (
          <>
            <RecentlyViewedCarouselContainer />
            <SectionSeparator />
          </>
        )}
        <Footer />
      </LazyLoadComponent>
    </>
  );
};

export const getStaticProps: GetStaticProps = storeWrapper.getStaticProps((store) => async () => {
  const props = await initializeApp(store, async () => {
    const isWhiteLabel = AppVariant.isWhitelabel();
    const contentApi = new ContentApi();
    const [cmsConfig, hero, usp, carousel, subCarousel] = await Promise.all([
      new ContentfulApi().getPageConfig({ pageId: CmsPages.HOME_PAGE }),
      getHeroData(),
      contentApi.getCarousel(2),
      contentApi.getCarousel(isWhiteLabel ? 7 : 8),
      contentApi.getCarousel(9)
    ]);

    return {
      cmsConfig,
      hero,
      usp: usp.tiles,
      carousel: carousel.tiles,
      subCarousel: subCarousel.tiles,
      promoBannerTripType: TripTypes.ALL
    };
  });
  return {
    ...props,
    revalidate: Revalidate.HALF_HOUR
  };
});

export default withGlobalProvider(withTheme(HomePage));
