import { ModalType } from '@state/modal/modalOperations';
import { BasketErrorModalContainer } from '@components/modal/basket/error/BasketErrorModalContainer';
import React from 'react';
import { FormSubmitSuccessModalContainer } from '@components/modal/form-submit-status/success/FormSubmitSuccessModalContainer';
import { FormSubmitErrorModalContainer } from '@components/modal/form-submit-status/error/FormSubmitErrorModalContainer';
import { SearchFormGuestsModalContainer } from '@components/modal/search-form-guests/SearchFormGuestsModalContainer';
import { TransferLuggageModalContainer } from '../review/';
import { GuestPageFormErrorContainer } from '@components/modal/form-error/guest-page-form-error/GuestPageFormErrorContainer';
import { PaymentPageErrorFormContainer } from '@components/modal/form-error/payment-page-form-error/PaymentPageFormErrorContainer';
import { PromoBannerModalModalContainer } from '../promo-banner';
import { ConfirmationModalContainer } from '@components/modal';
import { ContactFormErrorContainer } from '@components/modal/form-error/contact-form-error/ContactFormErrorContainer';
import { ValidationErrorModalContainer } from '../form-error/validation-error';
import { UnmatchedChildAgeModalContainer } from '../unmatched-child-age/UnmatchedChildAgeModalContainer';
import { MyBookingLoginFormErrorContainer } from '../form-error/mmb-login-form-error';
import { TravelDisclaimerFormErrorContainer } from '../form-error/travel-disclaimer-form-error';
import { PrivateTourEnquiryModalModalContainer } from '../private-tours/PrivateTourEnquiryModalContainer';
import { APISFormErrorContainer } from '../form-error/apis-form-error/APISFormErrorContainer';
import { DealFinderExpirationModalContainer } from '@components/deal-finder/results/token/DealFinderExpirationModalContainer';
import { ViewRoomTypesModalContainer } from '../product/';
import { SearchErrorModalContainer } from '@components/modal/search/error/SearchErrorModalContainer';
import { ToursErrorModalContainer } from '@components/modal/tours/error/ToursErrorModalContainer';
import { MyBookingDomainErrorContainer } from '@components/modal/mmb/MyBookingDomainErrorContainer';
import { GenericErrorModalContainer } from '@components/modal/error';
import { EarlyFlightsModalContainer } from '@components/modal/early-flights';
import { RyanairConfirmFlightsModalContainer } from '@components/modal/ryanair-flights-confirm';
import { ExitSeatSelectionModalContainer } from '@components/modal/exit-seat-selection';

export interface RootModalProps {
  modalType: ModalType;
  props?: any;
}

const modalMapping = {
  [ModalType.BASKET_ERROR]: BasketErrorModalContainer,
  [ModalType.DEAL_FINDER_TOKEN_EXPIRED]: DealFinderExpirationModalContainer,
  [ModalType.FORM_SUBMIT_SUCCESS]: FormSubmitSuccessModalContainer,
  [ModalType.FORM_SUBMIT_ERROR]: FormSubmitErrorModalContainer,
  [ModalType.SEARCH_GUEST_FORM_ERROR]: SearchFormGuestsModalContainer,
  [ModalType.TRANSFER_LUGGAGE_MODAL]: TransferLuggageModalContainer,
  [ModalType.GUEST_PAGE_FORM_ERROR]: GuestPageFormErrorContainer,
  [ModalType.PAYMENT_PAGE_FORM_ERROR]: PaymentPageErrorFormContainer,
  [ModalType.PROMO_BANNER_MODAL]: PromoBannerModalModalContainer,
  [ModalType.CONFIRMATION_MODAL]: ConfirmationModalContainer,
  [ModalType.CONTACT_FORM_ERROR]: ContactFormErrorContainer,
  [ModalType.VALIDATION_ERROR]: ValidationErrorModalContainer,
  [ModalType.UNMATCHED_CHILD_AGE]: UnmatchedChildAgeModalContainer,
  [ModalType.MMB_LOGIN_FORM_ERROR]: MyBookingLoginFormErrorContainer,
  [ModalType.TRAVEL_DISCLAIMER_FORM_ERROR]: TravelDisclaimerFormErrorContainer,
  [ModalType.PRIVATE_TOUR_ENQUIRY]: PrivateTourEnquiryModalModalContainer,
  [ModalType.APIS_FORM_ERROR]: APISFormErrorContainer,
  [ModalType.VIEW_ROOM_TYPES_MODAL]: ViewRoomTypesModalContainer,
  [ModalType.SEARCH_ERROR]: SearchErrorModalContainer,
  [ModalType.MMB_BOOKING_DOMAIN_ERROR]: MyBookingDomainErrorContainer,
  [ModalType.GENERIC_ERROR]: GenericErrorModalContainer,
  [ModalType.TOURS_ERROR]: ToursErrorModalContainer,
  [ModalType.EARLY_FLIGHTS_MODAL]: EarlyFlightsModalContainer,
  [ModalType.RYANAIR_CONFIRM_FLIGHTS_MODAL]: RyanairConfirmFlightsModalContainer,
  [ModalType.EXIT_SEAT_SELECTION]: ExitSeatSelectionModalContainer
};

export const RootModal = ({ modalType, props }: RootModalProps) => {
  if (modalType === ModalType.NONE) return null;
  const Modal = modalMapping[modalType];
  if (!Modal) return null;
  return <Modal {...props} />;
};
