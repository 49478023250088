import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import React from 'react';
import { TestId } from '@components/test-ids';
import { Theme, ThemeProps, SelectableThemeProps } from '@theme/base';
import { useI18NextContext } from '@components/hooks';
import { AppBar, Tabs, Tab } from '@mui/material';
import { Icon } from '@components/common/generic-icon';
import { mq } from '@styles/breakpoints';
import { ZIndex } from '@styles/z-index';
import { useDispatch, useSelector } from 'react-redux';
import { getTabIndex, setTabIndex } from '@state/search';

/* ***************** *
 *       Types       *
 * ***************** */

export interface TabSearchProps {
  theme?: Theme;
  testId?: string;
  children: Array<JSX.Element> | JSX.Element;
  tabLabels: Array<TabLabel>;
  fullWidth?: boolean;
}

interface TabLabel {
  label: string;
  icon: string;
}

/* ***************** *
 *       Styles      *
 * ***************** */

const TabSearchContainer = styled.div(({ theme }: ThemeProps) => ({
  width: '100%',

  ['.MuiTabs-root']: {
    minHeight: 50
  },

  ['.MuiTabs-scrollable']: {
    backgroundColor: theme.custom.colors.white,
    borderRight: `1px solid ${theme.custom.colors.group10.light}`,

    [mq.small]: {
      backgroundColor: 'transparent',
      borderRadius: `4px 4px 0 0`,
      borderRight: 'none'
    }
  },

  ['.MuiTab-root']: {
    alignItems: 'center',
    backgroundColor: theme.custom.colors.white,
    display: 'flex',
    flexDirection: 'row',
    fontSize: '1.6rem',
    fontWeight: 500,
    opacity: 1,
    padding: theme.custom.spacing.small,
    textTransform: 'none',
    transition: `color ${theme.custom.transitions.default}`,

    [mq.small]: {
      borderTop: `1px solid ${theme.custom.colors.group10.light}`
    },

    [mq.medium]: {
      minWidth: 180,
      padding: `${theme.custom.spacing.small}px ${theme.custom.spacing.medium}px`
    }
  },

  ['.MuiAppBar-root']: {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },

  ['.MuiTab-labelIcon']: {
    ['> i']: {
      fontSize: '1.4rem',
      marginBottom: 0,
      marginRight: theme.custom.spacing.xSmall,
      [mq.small]: {
        fontSize: '2rem',
        marginRight: theme.custom.spacing.small
      }
    }
  }
}));

interface NavBarTabSearchContainerProps extends ThemeProps {
  fullWidth: boolean;
}
const NavBarTabSearchContainer: any = styled(TabSearchContainer)(
  ({ theme, fullWidth }: NavBarTabSearchContainerProps) => ({
    ['.MuiTabs-root']: {
      justifyContent: 'center',
      ['.MuiTabs-scroller']: {
        padding: fullWidth ? '0 3%' : `0 ${theme.custom.spacing.medium}px`,
        maxWidth: fullWidth ? '100%' : theme.custom.contentSizes.maxContentWidth,
        ['.MuiTabs-flexContainer']: {
          justifyContent: 'space-between'
        }
      }
    },

    ['.MuiTab-root']: {
      minHeight: 50,
      backgroundColor: theme.custom.colors.white,
      transition: `background-color ${theme.custom.transitions.default}`,

      ['&.Mui-selected']: {
        color: theme.custom.colors.group1.base
      }
    },

    ['.MuiAppBar-root']: {
      backgroundColor: theme.custom.colors.white,
      zIndex: ZIndex.CONTENT
    },

    ['button, > i']: {
      color: theme.custom.colors.group4.base,
      ...(theme.custom.typography.paragraph as any)
    },

    ['.Mui-selected']: {
      ['button, > i']: {
        color: theme.custom.colors.group1.base
      }
    }
  })
);

const TabContentContainer = styled.div({
  position: 'relative'
});

interface TabContentItemProps extends SelectableThemeProps {
  isHomepage: boolean;
}
const TabContentItem: any = styled.div(({ isSelected }: TabContentItemProps) => ({
  display: isSelected ? 'flex' : 'none',
  justifyContent: 'center',
  padding: '10px 0',
  width: '100%'
}));

interface TabContentInnerProps extends ThemeProps {
  isHomepage: boolean;
  fullWidth: boolean;
}
const TabContentInner: any = styled.div(({ theme, fullWidth }: TabContentInnerProps) => ({
  width: '100%',
  padding: fullWidth ? '0 3%' : `0 ${theme.custom.spacing.medium}px`,
  maxWidth: fullWidth ? '100%' : theme.custom.contentSizes.maxContentWidth
}));

/* ************************ *
 *    TabSearchComponent    *
 * ************************ */

export const TabSearchComponent = (props: TabSearchProps) => {
  const t: any = useI18NextContext();
  const { children, tabLabels, fullWidth } = props;
  const dispatch = useDispatch();
  const tabIndex: number = useSelector(getTabIndex);

  /* *** LOGIC *** */
  const getChildrenArray = () => (Array.isArray(children) ? children : [children]) || [];

  function handleChange(event: any, newValue: number) {
    event.preventDefault();
    dispatch(setTabIndex(newValue));
  }

  /* *** RENDERERS *** */
  const renderTabsContent = () => {
    return getChildrenArray().map((child: JSX.Element, index: number) => (
      <TabContentItem
        data-testid={index === tabIndex ? TestId.tabSearch.item.selected : TestId.tabSearch.item.notSelected}
        key={index}
        isSelected={index === tabIndex}
      >
        <TabContentInner fullWidth={fullWidth}>{child}</TabContentInner>
      </TabContentItem>
    ));
  };

  const renderTabs = () =>
    tabLabels.map(({ label, icon }: TabLabel, index: number) => (
      <Tab key={`${label}-${index}`} icon={<Icon name={icon} />} label={t(label)} />
    ));

  return (
    <NavBarTabSearchContainer data-testid={props.testId || TestId.tabSearch.main} fullWidth={fullWidth}>
      <>
        <AppBar position="static" color="default">
          <Tabs value={tabIndex} onChange={handleChange} variant={'scrollable'}>
            {renderTabs()}
          </Tabs>
        </AppBar>
        <TabContentContainer>{renderTabsContent()}</TabContentContainer>
      </>
    </NavBarTabSearchContainer>
  );
};

export const TabSearch = withTheme(TabSearchComponent);
