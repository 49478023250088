import { BaseAction } from '@model/redux';
import { Price, DEFAULT_PRICE_OBJECT } from '@model/common';

export enum TourSummaryActions {
  SET_TOUR_SUMMARY = '@TOURS/SET_TOUR_SUMMARY',
  UNSET_TOUR_SUMMARY = '@TOURS/UNSET_TOUR_SUMMARY'
}

export interface TourSummaryState {
  destinationName: string;
  tourDescription: string;
  tourImage: string;
  tourName: string;
  connectId: string;
  tourPrice: Price;
}

export const INITIAL_TOUR_SUMMARY_STATE: TourSummaryState = {
  destinationName: '',
  tourDescription: '',
  tourImage: '',
  tourName: '',
  connectId: '',
  tourPrice: {
    ...DEFAULT_PRICE_OBJECT,
    amount: 0
  }
};

export interface SetTourSummaryAction extends BaseAction {
  type: TourSummaryActions.SET_TOUR_SUMMARY;
  payload: TourSummaryState;
}

export const setTourSummary: (params: TourSummaryState) => SetTourSummaryAction = (params: TourSummaryState) => ({
  type: TourSummaryActions.SET_TOUR_SUMMARY,
  payload: params
});

export interface UnsetTourSummaryAction extends BaseAction {
  type: TourSummaryActions.UNSET_TOUR_SUMMARY;
}

export const unsetTourSummary: () => UnsetTourSummaryAction = () => ({
  type: TourSummaryActions.UNSET_TOUR_SUMMARY
});

export const tourSummaryReducer: any = (state: any = INITIAL_TOUR_SUMMARY_STATE, { type, payload }: any) => {
  switch (type) {
    case TourSummaryActions.SET_TOUR_SUMMARY:
      return payload;
    case TourSummaryActions.UNSET_TOUR_SUMMARY:
      return INITIAL_TOUR_SUMMARY_STATE;
    default:
      return state;
  }
};
