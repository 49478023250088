import { useDispatch, useSelector } from 'react-redux';
import { closeModal, ModalType } from '@state/modal/modalOperations';
import React from 'react';
import { BaseModal } from '@components/common/modal';
import { getModalType, getModalData } from '@state/modal/modalSelectors';
import { ExitSeatSelectionModal } from './ExitSeatSelectionModal';

export const ExitSeatSelectionModalContainer = () => {
  const dispatch = useDispatch();
  const modalType = useSelector(getModalType);
  const modalData = useSelector(getModalData);
  const handleOnDismiss = () => dispatch(closeModal());

  return (
    <BaseModal open={modalType === ModalType.EXIT_SEAT_SELECTION} onDismiss={handleOnDismiss} hideCloseButton>
      <ExitSeatSelectionModal onDismiss={handleOnDismiss} {...modalData} />
    </BaseModal>
  );
};
