import { putResolve, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { BasketActions } from '@state/basket/basketActions';
import { RoutingActions } from '@state/app';
import { HYDRATE } from 'next-redux-wrapper';
import { isEqual } from 'lodash';
import { getModalData, getModalType } from './modalSelectors';
import { GuestsActions } from '@state/guests/guestOperations';
import { DealFinderResultsActions } from '@state/deal-finder-results/dealFinderResultsOperations';

export enum ModalActions {
  SHOW_MODAL = '@MODAL/SHOW_MODAL',
  CLOSE_MODAL = '@MODAL/CLOSE_MODAL',
  CLOSE_VIEW_ROOMS_MODAL = '@MODAL/CLOSE_VIEW_ROOMS_MODAL'
}

export enum ModalType {
  NONE = 'NONE',
  BASKET_ERROR = 'BASKET_ERROR',
  DEAL_FINDER_TOKEN_EXPIRED = 'DEAL_FINDER_TOKEN_EXPIRED',
  FORM_SUBMIT_SUCCESS = 'FORM_SUBMIT_SUCCESS',
  FORM_SUBMIT_ERROR = 'FORM_SUBMIT_ERROR',
  SEARCH_GUEST_FORM_ERROR = 'SEARCH_GUEST_FORM_ERROR',
  TRANSFER_LUGGAGE_MODAL = 'TRANSFER_LUGGAGE_MODAL',
  CONTACT_FORM_ERROR = 'CONTACT_FORM_ERROR',
  GUEST_PAGE_FORM_ERROR = 'GUEST_PAGE_FORM_ERROR',
  PAYMENT_PAGE_FORM_ERROR = 'PAYMENT_PAGE_FORM_ERROR',
  PROMO_BANNER_MODAL = 'PROMO_BANNER_MODAL',
  CONFIRMATION_MODAL = 'CONFIRMATION_MODAL',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
  UNMATCHED_CHILD_AGE = 'UNMATCHED_CHILD_AGE',
  MMB_LOGIN_FORM_ERROR = 'MMB_LOGIN_FORM_ERROR',
  TRAVEL_DISCLAIMER_FORM_ERROR = 'TRAVEL_DISCLAIMER_FORM_ERROR',
  PRIVATE_TOUR_ENQUIRY = 'PRIVATE_TOUR_ENQUIRY',
  APIS_FORM_ERROR = 'APIS_FORM_ERROR',
  VIEW_ROOM_TYPES_MODAL = 'VIEW_ROOM_TYPES_MODAL',
  SEARCH_ERROR = 'SEARCH_ERROR',
  MMB_BOOKING_DOMAIN_ERROR = 'MMB_BOOKING_DOMAIN_ERROR',
  GENERIC_ERROR = 'GENERIC_ERROR',
  TOURS_ERROR = 'TOURS_ERROR',
  PAYWALL_BANNER_MODAL = 'PAYWALL_BANNER_MODAL',
  EARLY_FLIGHTS_MODAL = 'EARLY_FLIGHTS_MODAL',
  RYANAIR_FLIGHTS_CANCELLATION_ERROR = 'RYANAIR_FLIGHTS_CANCELLATION_ERROR',
  RYANAIR_CONFIRM_FLIGHTS_MODAL = 'RYANAIR_CONFIRM_FLIGHTS_MODAL',
  EXIT_SEAT_SELECTION = 'EXIT_SEAT_SELECTION'
}

interface ModalHistory {
  hasClosedViewRoomTypesModal: boolean;
}

export interface ModalState {
  modalType: ModalType;
  modalData: any;
  modalHistory: ModalHistory;
}

export const INITIAL_MODAL_STATE: ModalState = {
  modalType: ModalType.NONE,
  modalData: {},
  modalHistory: {
    hasClosedViewRoomTypesModal: false
  }
};

export function* onBasketError() {
  yield takeEvery(
    [
      BasketActions.RECEIVE_ADD_TO_BASKET_FAILURE,
      BasketActions.RECEIVE_BASKET_FAILURE,
      GuestsActions.RECEIVE_GUESTS_FAILURE
    ],
    handleShowBasketErrorModal
  );
}

export function* onDealFinderTokenExpired() {
  yield takeEvery([DealFinderResultsActions.TOKEN_EXPIRED], handleShowDealFinderTokenExpiredModal);
}

export function* onLocationChange() {
  yield takeLatest(RoutingActions.LOCATION_CHANGE, handleOnLocationChange);
}

export function* handleShowBasketErrorModal({ payload }: any) {
  yield putResolve(showModal(ModalType.BASKET_ERROR, { code: payload }));
}

export function* handleShowDealFinderTokenExpiredModal() {
  yield putResolve(showModal(ModalType.DEAL_FINDER_TOKEN_EXPIRED));
}

export function* handleOnLocationChange() {
  const modalType = yield select(getModalType);
  const modalData = yield select(getModalData);
  if (modalType !== ModalType.NONE && !modalData.persist) {
    yield putResolve(closeModal());
  }
}

export const showModal = (modalType: ModalType, modalData?: any) => ({
  type: ModalActions.SHOW_MODAL,
  modalType,
  modalData
});

export const closeModal = () => ({ type: ModalActions.CLOSE_MODAL });

export const closeViewRoomsModal = () => ({ type: ModalActions.CLOSE_VIEW_ROOMS_MODAL });

export const modalReducer: any = (state: ModalState = INITIAL_MODAL_STATE, action: any) => {
  let modalHistory;
  switch (action.type) {
    case HYDRATE:
      if (isEqual(state, INITIAL_MODAL_STATE)) {
        return action.payload?.app?.modal || state;
      }
      return state;
    case ModalActions.SHOW_MODAL:
      return { ...state, modalType: action.modalType, modalData: action.modalData || {} };
    case ModalActions.CLOSE_MODAL:
      return INITIAL_MODAL_STATE;
    case ModalActions.CLOSE_VIEW_ROOMS_MODAL:
      modalHistory = {
        ...state.modalHistory,
        hasClosedViewRoomTypesModal: true
      };
      return {
        ...INITIAL_MODAL_STATE,
        modalHistory
      };
    default:
      return state;
  }
};
