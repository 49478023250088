import { ModalContainer, Container, Heading, ModalContent, ButtonsContainer, CTAButton } from '@styles/modal';
import { useI18NextContext } from '@components/hooks';
import { ButtonVariant, ButtonColor, ButtonSize } from '@components/material-ui';
import { TextMarkdown } from '@components/common/description';

/* ***************** *
 *       Types       *
 * ***************** */
export interface RyanairConfirmFlightsModalProps {
  onDismiss: () => void;
  onSelect: () => void;
}

export const ExitSeatSelectionModal = ({ onDismiss, onSelect }: RyanairConfirmFlightsModalProps) => {
  const t: any = useI18NextContext();

  return (
    <ModalContainer>
      <Container>
        <Heading>{t('review__seat-maps--exit-modal-title')}</Heading>
        <ModalContent>
          <TextMarkdown description={t('review__seat-maps--exit-modal-text')} />
        </ModalContent>
        <ButtonsContainer>
          <CTAButton
            variant={ButtonVariant.CONTAINED}
            color={ButtonColor.SECONDARY}
            size={ButtonSize.REGULAR}
            label={t('review__seat-maps--exit-modal-button-select')}
            onClick={onSelect}
          />
          <CTAButton
            variant={ButtonVariant.OUTLINED}
            color={ButtonColor.SECONDARY}
            size={ButtonSize.REGULAR}
            label={t('review__seat-maps--exit-modal-button-cancel')}
            onClick={onDismiss}
          />
        </ButtonsContainer>
      </Container>
    </ModalContainer>
  );
};
