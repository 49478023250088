import React from 'react';
import { useSelector } from 'react-redux';
import { TripTypes } from '@model/common/tours/trip-types';
import { TAB_INDEXES } from '@model/search/common';
import { getTabIndex } from '@state/search';
import { TabSearch } from '@components/search/tabs';
import { TourSearchContainer } from '@components/search/tabs/tours/TourSearchContainer';
import { DealFinderSearchContainer } from '@components/search/tabs/deal-finder/DealFinderSearchContainer';
import { HotelOnlySearchContainer } from '@components/search/tabs/deal-finder/HotelOnlySearchContainer';

export interface SearchTabsBarDevProps {
  condensed?: boolean;
  onSearch?: () => void;
}

export const SearchTabsBar = ({ condensed, onSearch }: SearchTabsBarDevProps) => {
  const tabIndex = useSelector(getTabIndex);

  return (
    <>
      <TabSearch
        tabLabels={[
          { label: 'common__label--holidays', icon: 'mhi-holidays' },
          { label: 'tab-search__label--hotel-only', icon: 'mhi-hotel' },
          { label: 'tab-search__label--tours', icon: 'mhi-tours' },
          { label: 'tab-search__label--river-cruises', icon: 'mhi-boat' },
          { label: 'tab-search__label--private-tours', icon: 'mhi-tours' },
          { label: 'tab-search__label--multi-centres', icon: 'mhi-multi-stay' },
          { label: 'tab-search__label--cruises', icon: 'mhi-boat' }
        ]}
      >
        <DealFinderSearchContainer
          condensed={condensed}
          onSearch={onSearch}
          isVisible={tabIndex === TAB_INDEXES.HOLIDAYS}
        />
        <HotelOnlySearchContainer
          condensed={condensed}
          onSearch={onSearch}
          isVisible={tabIndex === TAB_INDEXES.HOTEL_ONLY}
        />
        <TourSearchContainer
          tripType={TripTypes.ESCORTED_TOURS}
          condensed={condensed}
          isVisible={tabIndex === TAB_INDEXES.ESCORTED_TOURS}
        />
        <TourSearchContainer
          tripType={TripTypes.RIVER_CRUISES}
          condensed={condensed}
          isVisible={tabIndex === TAB_INDEXES.RIVER_CRUISES}
        />
        <TourSearchContainer
          tripType={TripTypes.PRIVATE_TOURS}
          condensed={condensed}
          isVisible={tabIndex === TAB_INDEXES.PRIVATE_TOURS}
        />
        <TourSearchContainer
          tripType={TripTypes.MULTI_CENTRE}
          condensed={condensed}
          isVisible={tabIndex === TAB_INDEXES.MULTI_CENTRE}
        />
        <TourSearchContainer
          tripType={TripTypes.CRUISES}
          condensed={condensed}
          isVisible={tabIndex === TAB_INDEXES.CRUISES}
        />
      </TabSearch>
    </>
  );
};

export default SearchTabsBar;
