import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styled from '@emotion/styled';
import { Collapse } from '@mui/material';
import Fab from '@mui/material/Fab';
import { Theme, ThemeProps } from '@theme/base';
import { breakpoints, mq } from '@styles/breakpoints';
import { mixins } from '@styles/mixins';
import { ZIndex } from '@styles/z-index';
import { HeaderContainer, NavBarWrapper } from '@styles/layout';
import { getNavConfig } from '@config/navigation';
import { Page, SortOptions } from '@model/common';
import { Months } from '@model/common/dates';
import { ContentTypes } from '@model/navigation';
import { TripTypes } from '@model/contentful';
import { AgentPage } from '@model/common/pages/agent-pages';
import { AppVariant } from '@model/config/brands';
import { getCMSContactDetails } from '@state/cms';
import { getPath } from '@state/app';
import { getPromoBannerData } from '@state/promo-banner/promoBannerSelectors';
import { useI18NextContext } from '@components/hooks';
import { TestId } from '@components/test-ids';
import { withIsServer } from '@components/hoc';
import { Icon } from '@components/common/generic-icon/Icon';
import { LogoContainer } from '@components/common/logo';
import { PhoneNumber } from '@components/common/navigation/phone-number/PhoneNumber';
import { DesktopNavItem } from '@components/common/navigation/DesktopNavItem';
import { NavLabel } from '@components/common/navigation/NavLabel';
import { AgentsNavContainer } from '@components/agents/nav/AgentsNavContainer';
import { useIsAgent } from '@components/hooks/isAgent';
import { useResize } from '@components/hooks';
import { PromoBannerContainer } from '@components/common/banner/promo/PromoBannerContainer';
import { DesktopNavigation } from './DesktopNavigation';
import { MobileNavigationContainer } from './MobileNavigationContainer';

/* ***************** *
 *       Types       *
 * ***************** */

export interface NavBarProps {
  theme?: Theme;
  showFiltersBarDrawer?: boolean;
  renderSearch?: JSX.Element;
  testId?: string;
  selectedOptions: SelectedOptions;
  setOptions: SetOptions;
  isServer?: boolean;
  promoBannerTripType?: TripTypes;
}

export interface SelectedOptions {
  selectedAirport: string;
  selectedMonth: Months;
  selectedSortOption: SortOptions;
  selectedFilter: TripTypes;
}

export interface SetOptions {
  setSelectedAirport: (payload: string) => void;
  setSelectedFilter: (payload: TripTypes) => void;
  setSelectedSortOption: (payload: SortOptions) => void;
  setSelectedMonth: (payload: Months) => void;
}

export interface NavigationRow {
  label: string;
  desktopContent?: ContentTypes;
  mobileContent?: ContentTypes;
  heading?: string;
  nodes?: Array<NavigationRow>;
  tripType?: TripTypes;
}

/* ***************** *
 *       Styles      *
 * ***************** */
const Container = styled.div(({ theme }: ThemeProps) => ({
  width: '100%',
  height: theme.custom.spacing.xxLarge,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  zIndex: 1,

  ['.MuiFab-root']: {
    backgroundColor: theme.custom.colors.group1.base,

    ['&:hover']: {
      backgroundColor: theme.custom.colors.group1.dark
    },

    [mq.large]: {
      display: 'none'
    }
  }
}));

const Hamburger: any = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: 40,
  height: 40,
  cursor: 'pointer',
  zIndex: ZIndex.NAVIGATION,

  [mq.large]: {
    display: 'none'
  }
});

const hamburgerStyles: (theme: Theme) => any = (theme: Theme) => ({
  color: theme.custom.colors.white,
  ...mixins(theme).defaultDropShadow.textShadow,
  fontSize: '2rem'
});

const DesktopNavContainer: any = styled.ul(({ theme }: ThemeProps) => ({
  display: 'none',

  [mq.large]: {
    display: 'flex',
    listStyle: 'none',
    padding: 0,
    marginLeft: theme.custom.spacing.large,
    flex: 1,
    justifyContent: 'flex-end'
  }
}));

const navIconStyles: (theme: Theme) => any = (theme: Theme) => ({
  marginTop: 1,
  marginLeft: theme.custom.spacing.xSmall,
  color: theme.custom.colors.white,
  transition: `color ${theme.custom.transitions.default}`,
  ...mixins(theme).defaultDropShadow.textShadow,
  fontSize: '1rem'
});

export const SearchWrapper: any = styled.div(({ theme }: ThemeProps) => ({
  backgroundColor: theme.custom.colors.group10.lighter,
  transition: 'all 0.5s ease',
  display: 'flex',
  justifyContent: 'center',
  margin: '0 auto',

  ['.MuiCollapse-hidden']: {
    [mq.large]: {
      visibility: 'visible'
    }
  },

  ['.MuiCollapse-root']: {
    [mq.large]: {
      height: 'auto !important',
      overflow: 'visible'
    }
  }
}));

const SearchButton = styled(Fab)(({ theme }: ThemeProps) => ({
  backgroundColor: theme.custom.colors.group1.base,
  borderRadius: 30,
  ':hover': {
    backgroundColor: theme.custom.colors.group1.light
  }
}));

const SearchIcon = styled(Icon)(({ theme }: ThemeProps) => ({
  fontSize: '2rem',
  color: theme.custom.colors.white
}));

export const CollapseContainer = styled(Collapse)({
  width: '100%'
});

const NavItemIcon = styled(Icon)({
  marginTop: 3
});

const NavItemWrapper = styled.div({
  cursor: 'pointer',
  display: 'flex',
  flexWrap: 'nowrap',
  whiteSpace: 'nowrap',
  alignItems: 'center'
});

/* *********************** *
 *         NavBar          *
 * *********************** */
export const NavBarComponent = withIsServer((props: NavBarProps) => {
  const { theme, selectedOptions, setOptions, renderSearch, isServer, promoBannerTripType } = props;
  const t: any = useI18NextContext();
  const isAgent = useIsAgent();
  const router = useRouter();
  const promoBannerData = useSelector(getPromoBannerData);
  const statePath = useSelector(getPath);
  const contactDetails = useSelector(getCMSContactDetails);
  const mainTelephoneNumber = contactDetails && contactDetails.fields.mainTelephoneNumber;
  const isWhiteLabel = AppVariant.isWhitelabel();

  /* *** STATE *** */
  const [showMobileNav, setShowMobileNav] = useState<boolean>(false);
  const [navItemIndex, setNavItemIndex] = useState<number>(-1);
  const [showSearch, setShowSearch] = useState<boolean>(router.asPath === Page.HOME);
  const [isDesktopView] = useResize();

  /* *** LOGIC *** */
  const toggleDesktopNav = (itemIndex?: number) => {
    setNavItemIndex(itemIndex || itemIndex === 0 ? itemIndex : -1);
    if (showMobileNav && document && document.documentElement.clientWidth > breakpoints.medium) {
      setShowMobileNav(false);
    }
  };

  /* *** RENDERERS *** */
  const renderNavItems = () => [
    ...getNavConfig().map(
      (item: NavigationRow, index: number) =>
        item.desktopContent && (
          <DesktopNavItem
            key={`${item.label}-${index}`}
            data-testid={TestId.navBar.desktopNavItem}
            onMouseEnter={() => toggleDesktopNav(index)}
            onMouseLeave={() => toggleDesktopNav(-1)}
          >
            <NavItemWrapper>
              <NavLabel isClickable={true}>{t(item.label)}</NavLabel>
              <NavItemIcon name={'mhi-chevron-down'} css={navIconStyles(theme!)} />
            </NavItemWrapper>
            <DesktopNavigation
              navContent={item.desktopContent}
              selectedOptions={selectedOptions}
              setOptions={setOptions}
              show={index === navItemIndex}
              onToggleDesktopNav={toggleDesktopNav}
            />
          </DesktopNavItem>
        )
    ),
    ...(!isWhiteLabel
      ? [
          <DesktopNavItem key={'help'} data-testid={TestId.navBar.desktopNavItemHelp}>
            <NavLabel isClickable={true} noIcon>
              <Link href={Page.HELP_CENTRE} passHref>
                {t('common__label--help')}
              </Link>
            </NavLabel>
          </DesktopNavItem>
        ]
      : []),
    <DesktopNavItem key={'my-booking'} data-testid={TestId.navBar.desktopNavItem}>
      <NavLabel isClickable={true} noIcon>
        <Link
          href={isAgent ? Page.AGENTS.concat(AgentPage.BOOKINGS) : Page.MY_BOOKING}
          passHref
          suppressHydrationWarning
        >
          {t('nav__my-booking')}
        </Link>
      </NavLabel>
    </DesktopNavItem>,
    <DesktopNavItem key={'contact'}>
      <NavLabel isClickable={true} noIcon>
        <Link href={Page.HELP.concat(Page.CONTACT_US)} passHref>
          {t('nav__contact')}
        </Link>
      </NavLabel>
    </DesktopNavItem>
  ];

  return (
    <>
      <AgentsNavContainer />
      <HeaderContainer>
        <NavBarWrapper>
          <Container data-testid={props.testId || TestId.navBar.main}>
            <Hamburger
              data-testid={TestId.navBar.hamburger}
              onClick={() => setShowMobileNav(true)}
              style={hamburgerStyles(theme!)}
            >
              <Icon name={'mhi-menu'} css={hamburgerStyles(theme!)} />
            </Hamburger>
            <LogoContainer />
            {(isServer || isDesktopView) && (
              <DesktopNavContainer data-testid={TestId.navBar.desktopNavContainer}>
                {renderNavItems()}
                {mainTelephoneNumber && <PhoneNumber telephoneNumber={mainTelephoneNumber} />}
              </DesktopNavContainer>
            )}
            {renderSearch && !isWhiteLabel && (
              <>
                <SearchButton onClick={() => setShowSearch(!showSearch)} aria-label={'Search Button'}>
                  <SearchIcon name={'mhi-search'} />
                </SearchButton>
              </>
            )}
            {!isDesktopView && (
              <MobileNavigationContainer onDismiss={() => setShowMobileNav(false)} open={showMobileNav} />
            )}
          </Container>
        </NavBarWrapper>
        {renderSearch && !isWhiteLabel && (
          <SearchWrapper showSearch={showSearch}>
            <CollapseContainer in={showSearch}>{renderSearch}</CollapseContainer>
          </SearchWrapper>
        )}
        {promoBannerTripType && (
          <PromoBannerContainer path={statePath} promoBanners={promoBannerData} tripType={promoBannerTripType} />
        )}
      </HeaderContainer>
    </>
  );
});
